
/* selected cell */
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content{
    border-radius: 40px !important;
    background-color: #094553 !important;
    font-weight: 500 !important;
}
.rs-calendar-table-cell-selected{
    background-color: #E2FEF1 !important;
    padding: 5px !important;
    border-radius: 43px 0 0 43px !important;
}
.rs-calendar-table-cell-selected-end{
    background-color: #E2FEF1 !important;
    padding: 5px !important;
    border-radius: 0 43px 43px 0 !important;

}

/* cells between selected cells*/
.rs-calendar-table-cell-in-range{
    background-color: #E2FEF1 !important;
}


.rs-calendar-table-cell{
    height: 40px !important;
    padding: 5px !important;
}

/* hover effects */
.rs-calendar-table-cell-content:hover{
    background-color: #E2FEF1 !important;
    color: black !important;
}
.rs-calendar-table-cell:hover .rs-calendar-table-cell-content{
    background-color: #E2FEF1 !important;
    color: black !important;
}
.rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content{
    border-radius: 40px !important;
    background-color: #094553 !important;
    color: white !important;
}
.rs-calendar-table-cell-in-range::before {
    background-color: rgba(255, 255, 255, 0) !important;

}


/* same day selected for start and end*/
.rs-calendar-table-cell-selected.rs-calendar-table-cell-selected-start.rs-calendar-table-cell-selected-end{
    border-radius: 43px !important;
}


.rs-stack-item .rs-btn-link{
    border: 1px solid #094553 !important;
    background-color: transparent !important;
    color: #094553 !important;
    border-radius: 99px !important;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.125em;
    padding: 0 1rem;
    height: 2.125rem;
}

.rs-stack-item .rs-btn-link:hover {
    background-color: #E2FEF1 !important;
    text-decoration: none;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    -webkit-box-shadow: inset 0 0 0 1px #094553 !important;
    -webkit-box-shadow: inset 0 0 0 1px #094553 !important;
    box-shadow: inset 0 0 0 1px #094553 !important;
    box-shadow: inset 0 0 0 1px #094553 !important;
}
.rs-picker-toggle-value{
    color: black !important;
}
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
    /* border-color: #094553 !important;
    border-color:  #3e7660 !important; */
}
.rs-picker-daterange-header{
    display: none;
}

.rs-calendar{
    border: none !important;
}

.rs-btn.rs-btn-primary{
    background-color: #094553 !important;
    border-radius: 99px !important;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25em;
    height: 2.75rem;
    padding: 0 2rem;
}

.rs-picker-toggle-active, .rs-picker.rs-picker-focused {
    -webkit-box-shadow: 0 0 0 3px #094553 !important; 
    -webkit-box-shadow: #094553 !important; 
    box-shadow: 0 0 0 3px #094553 !important;
    box-shadow: #094553 !important;
}

.rs-picker-daterange-calendar-group, .rs-calendar {
    height: auto !important;
}

.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active{
    background-color: #094553 !important;
}

.rs-picker-toolbar {
    border: none !important;
}

.rs-calendar-header-month-toolbar {
    display: flex !important;
    justify-content: space-between;
    align-items: center !important;
}

.rs-calendar-header-forward svg, .rs-calendar-header-backward svg {
    height: 1.5rem !important;
    width: 1.5rem !important;
}

.rs-calendar-time-dropdown-cell:hover {
    background-color: #E2FEF1 !important;
    color: black !important;
}

.rs-calendar-body {
    padding-top: 16px;
}

.rs-calendar-header-title {
    font-size: 1rem !important;
    font-weight: 500 !important;
}

.rs-calendar-header-title.rs-calendar-header-title-time{
    color: #3e7660 !important;
}

.rs-calendar-table-header-cell span, .rs-calendar-table-cell-content {
    font-size: 1rem;
    line-height: 1.3;
}

/* .rs-picker.rs-picker-daterange:hover {
    border-color: hsl(0, 0%, 70%) !important;
} */

.rs-picker.rs-picker-daterange:hover:focus {
    /* border-color: #2684FF !important; */
}

.rs-picker-default .rs-picker-toggle{
    border: none !important;
}

.rs-picker.rs-picker-daterange {
    -webkit-align-items: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    background-color: #FFF !important;
    border-color: #D4D4D8 !important;
    border-radius: 8px !important;
    border-style: solid !important;
    border-width: 2px !important;
    cursor: default !important;
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-flex-wrap: wrap !important;
    -webkit-flex-wrap: wrap !important;
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    justify-content: space-between !important;
    min-height: 41px !important;
    outline: 0 !important;
    position: relative !important;
    -webkit-transition: all 100ms !important;
    transition: all 100ms !important;
    box-sizing: border-box !important;
    opacity: 1 !important;
}


.rs-picker-menu, .rs-anim-fade.rs-anim-in.rs-picker-daterange-menu.rs-picker-menu.placement-bottom-end{
    z-index: 9999 !important;
    border-radius: 16px !important;
}