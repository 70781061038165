@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'react-datetime-picker/dist/DateTimePicker.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.yumminn-input {
  @apply input input-bordered w-full my-0 rounded-md focus:border-primary focus:bg-primary focus:bg-opacity-30 !important;
  height: 44px !important;
  font-size: 12px !important;
}

.yumminn-input-small {
  @apply input input-bordered w-full my-0 rounded-md focus:border-primary focus:bg-primary focus:bg-opacity-30 !important;
  height: 38px !important;
  font-size: 12px;
}

.yumminn-input-date {
  @apply input input-bordered rounded-md text-base font-normal focus:border-primary focus:bg-primary focus:bg-opacity-30 !important;
  height: 100% !important;
}

.yumminn-input-small-modified {
  @apply input input-bordered w-48 my-0 focus:border-primary focus:bg-primary focus:bg-opacity-30 !important;
  height: 38px !important;
  font-size: 12px !important;
}

.yumminn-input-modified {
  @apply input input-bordered w-full my-0 rounded-md focus:border-primary focus:bg-primary focus:bg-opacity-30 !important;
  height: 44px !important;
  font-size: 16px !important;
  border-color: hsla(215 27.9% 16.9% / 0.2) !important;
  border-width: 1px !important;
  border-radius: var(0.5rem, .5rem) !important;
}

.table-cell-gray {
  @apply bg-trueGray-100 border-none !important;
}

input[type='text']:focus { box-shadow: none; }

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

body:has(.yumminn_popup) {
  overflow: hidden;
}

.svd_commercial_container,
.sa-commercial {
  display: none !important;
}

.Toastify__toast {
  border-radius: 10px !important;
  font-family: 'Poppins', sans-serif;
  z-index: 999999999999999999;
}

.Toastify__toast * {
  font-family: 'Poppins', sans-serif;
  z-index: 999999999999999999;
}

.Toastify__toast--success {
  color: #222 !important;
  background-color: #F3FFFA !important;
  z-index: 999999999999999999;
}

.Toastify__close-button--success {
  color: #222 !important;
}

.Toastify__toast--success.bordered {
  border: 1px solid #094553;
}

.Toastify__toast-container:has(.bordered) {
  width: 30rem;
}

.Toastify__toast-container:has(.bordered) .Toastify__close-button {
  align-self: center;
  margin-left: 8px;
}

.Toastify__toast-container:has(.bordered) .Toastify__close-button svg {
  width: 24px;
  height: 24px;
}

.sv_main .sv_header__text {
  width: 100% !important;
  max-width: 100% !important;
}

input:checked + div {
  @apply border-primary;
}
input:checked + div svg {
  @apply block;
}

.filepond--drop-label {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent ;
  border-radius: 8px;
  font-size: 14px !important;
  font-weight: 900;
}

.filepond--root {
  margin-bottom: 0px !important;
}

.filepond--panel-root {
  background-color: #F7F7F7;
}

.filepond--file-wrapper.filepond--file-wrapper>legend {
  clipPath: none;
  position: inherit;
  color: #404040;
  overflow: inherit;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-left: -50px;
  
}

[role="progressbar"] {
    width: 75%;
}

* {
  /* Firefox scrollbars */
  scrollbar-width: thin;
  scrollbar-color: #D6DEE1 transparent;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  padding: 0px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}


/* Removes filename from filepond component */
fieldset legend{
  display: none;
}

.yumminn-card {
  @apply bg-white border-2 border-gray-200 rounded-2xl overflow-hidden;
}

.yumminn-card-title-large {
  @apply text-2xl font-bold capitalize mb-2;
  line-height: 1em !important;
  color: #404040;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button,
input[type='number'] {
  -webkit-appearance: none;
  -moz-appearance: textfield !important;
  margin: 0;
}

.loyalty-overview-grid:nth-child(6),
.loyalty-overview-grid:first-child {
  stroke-opacity: 0;
}

.capitalize_first::first-letter {
  text-transform: capitalize;
}

*:has(> .yumminn_tooltip) {
  position: relative;
}

*:has(> .yumminn_tooltip):hover .yumminn_tooltip {
  opacity: 1;
  scale: 1;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}

.capitalize-first::first-letter {
  text-transform: capitalize;
}