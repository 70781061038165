.custom-input {
    background: url('../../img/calendar.svg') no-repeat right;
    background-position: right 10px center;
    background-size: 20px;
    width: 100%;
  }

  .react-datepicker-wrapper, .react-datepicker__input-container, .react-datepicker__input-container input {
    display: block;
    width: 100%!important; 
    margin-right: 80px;
    margin-left: 0px;
    border-color: hsl(0, 0%, 80%);
    box-sizing: border-box;
    height: 58px;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 14px;
    border-radius: 10px;  
  }