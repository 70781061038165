@keyframes shake {
  0% {
    translate: 0;
  }
  25% {
    translate: -2px 0;
  }
  50% {
    translate: 0;
  }
  75% {
    translate: 2px 0;
  }
  100% {
    translate: 0;
  }
}

@keyframes intermitentoutline {
  0% {
    outline-color: transparent;
  }
  100% {
    outline-color: #5FB894;
  }
}

@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}

.shake {
  animation: shake 0.2s ease-in-out 0s 5;
}

.intermitent-outline {
  outline: 3px solid #5FB894;
  outline-offset: 1px;
  z-index: 99;
  animation: intermitentoutline 1s ease-in-out alternate infinite;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 1.5s ease-in-out forwards;
}