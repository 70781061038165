.checkboxRestaurantSelect[type="checkbox"] {
    display: none;
  }
  .checkboxRestaurantSelect[type="checkbox"] + span {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 12px;
    height: 12px;
    margin: -1px 0px 0 0;
    vertical-align: middle;
    background: white left top no-repeat;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  .checkboxRestaurantSelect[type="checkbox"]:checked + span {
    background: #E18684 -19px top no-repeat;
  }
  
  .checkboxRestaurantSelect[type="checkbox"]:checked + span:before {
    content: "✔";
    color: white;
    font-size: 10px;
    position: absolute;
    top: -1.5px;
    left: 1.2px;
  }
  
  .checkboxRestaurantSelect[type="checkbox"] + span {
    margin-right: 10px;
  }

 