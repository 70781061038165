.custom-toast {
  display: flex;
  align-items: center;
  border-radius: 8px;
  color: #404040;
  font-size: 16px;
  font-family: Poppins, sans-serif;
}

.custom-toast-icon {
  font-size: 24px;
  margin-right: 10px;
}

.Toastify__toast--default {
  background-color: #F7FFFC!important;
  border: 1px solid #094553 !important;
  border-radius: 8px !important;  /* Color del borde */
}