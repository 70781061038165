/* use a hand cursor intead of arrow for the action buttons */
.filepond--file-action-button {
  cursor: pointer;
}

/* the text color of the drop label*/
.filepond--drop-label {
  color: #555;
}

/* underline color for "Browse" button */
.filepond--label-action {
  text-decoration-color: #aaa;
}

/* the background color of the filepond drop area */
.filepond--panel-root {
  background-color: #eee;
}

/* the border radius of the drop area */
.filepond--panel-root {
  border-radius: 0.5em;
}

/* the border radius of the file item */
.filepond--item-panel {
  border-radius: 0.5em;
}

/* the background color of the file and file panel (used when dropping an image) */
.filepond--item-panel {
  background-color: #555;
}

/* the background color of the drop circle */
.filepond--drip-blob {
  background-color: #999;
}

/* the background color of the black action buttons */
.filepond--file-action-button {
  background-color: rgba(0, 0, 0, 0.5);
}

/* the icon color of the black action buttons */
.filepond--file-action-button {
  color: white;
}

/* the color of the focus ring */
.filepond--file-action-button:hover,
.filepond--file-action-button:focus {
  box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.9);
}

/* the text color of the file status and info labels */
.filepond--file {
  color: white;
}

/* error state color */
[data-filepond-item-state*='error'] .filepond--item-panel,
[data-filepond-item-state*='invalid'] .filepond--item-panel {
  background-color: red;
}

[data-filepond-item-state='processing-complete'] .filepond--item-panel {
  background-color: green;
}

.filepond--wrapper {
  height: 15rem;
  width: 15rem;
  border: 2px dashed #D0D0D0;
  border-radius: 6px;
}

.filepond--hopper,
.filepond--drop-label {
  height: 100% !important;
  width: 100% !important;
}

.filepond--browser {
  height: 100% !important;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
}

.filepond--panel-root {
  border-radius: 4px;
  height: 100%;
  overflow: hidden;
}

.filepond--item,
.filepond--file-wrapper,
.filepond--file,
.filepond--image-clip,
.filepond--image-canvas-wrapper,
.filepond--image-bitmap {
  height: 100% !important;
  width: 100% !important;
}

.filepond--image-canvas-wrapper {
  transform: none !important;
}

.filepond--image-bitmap canvas {
  height: 100% !important;
  width: 100% !important;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}