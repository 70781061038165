.react-datepicker{
    margin-top: 22px;
}

.react-datepicker__input-container{
    width: 100px!important;
}

.react-datepicker-wrapper{
    width: 100px!important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 150px;
    margin-right: 80px;
    margin-left: 3px;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    box-sizing: border-box;
    height: 38px;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 14px;
}

@media (max-width: 760px) {
    .react-datepicker__input-container input {
        width: 108px;
    }


}

.react-datepicker__month-container{
    font-family: Poppins, sans-serif;
    z-index: 9999;
}

.react-datepicker__month--in-range{
    background-color: #5FB894!important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: #5FB894!important;
}

[type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus{
    border-color: #5FB894!important;
    --tw-ring-color: #5FB894!important;
}

.react-datepicker__month-select{
    border-radius: 4px;
}

.css-1s2u09g-control:focus{
    border: 1px solid #5FB894!important;
}

.css-1pahdxg-control:focus{
    border: 1px solid #5FB894!important;
}

.css-1pahdxg-control:hover{
    border: 1px solid #5FB894!important;
}

.css-1pahdxg-control{
    box-shadow: 0 0 0 1px #5FB894!important;
    border: 1px solid #5FB894!important;
}

.css-1s2u09g-control:focus{
    box-shadow: 0 0 0 1px #5FB894!important;
    border: 1px solid #5FB894!important;
}

