.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.react-datetime-picker.react-datetime-picker--closed.react-datetime-picker--enabled,
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 100%!important;
}

.react-datetime-picker__inputGroup__input--hasLeadingZero{
    margin-left: 0!important;
}

.react-time-picker__inputGroup__leadingZero{
    margin-top: 1px!important;
}

.react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__minute.react-datetime-picker__inputGroup__input--hasLeadingZero{
    padding: 0!important;
}
