.filepond--browser.filepond--browser {
    position: absolute;
    margin: 0;
    padding: 0;
    left: -100px;
    top: -20px;
    width: 500px;
    height: 111px;
    opacity: 0;
    font-size: 0;
    cursor: pointer;
}