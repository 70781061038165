.header-grid-container {
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid black;
    padding-bottom: 10px;
}
.content-grid-container {
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
}
.translate-button {
    display: flex;
    align-items: center;
    align-self: flex-end;
    gap: 10px;
    justify-content: center;
    border: 1px #094553 solid;
    border-radius: 40px;
    font-weight: 500;
    font-size: 14px;
    color: white;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    background-color: #094553;
    &:hover {
        margin-bottom: -1px;
    }
}
.language-selector-container {
    display: flex;
    align-items: center;
    gap: 10px;
}
.language-selector-container p {
    font-weight: 600;
    font-size: 16px;
}
.filters-container {
    margin: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.styledCheckbox[type="checkbox"] {
    display: none;
  }
  .styledCheckbox[type="checkbox"] + span {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 17px;
    height: 17px;
    background: white left top no-repeat;
    border: 2px solid #626262;
    cursor: pointer;
  }
  .styledCheckbox[type="checkbox"]:checked + span {
    background: #3AD697 -19px top no-repeat;
  }
  
  .styledCheckbox[type="checkbox"]:checked + span:before {
    content: "✔";
    font-weight: 600;
    color: #212121;
    font-size: 11px;
  }
  
  .styledCheckbox[type="checkbox"] + span {
    margin-right: 10px;
  }

 