
  .container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loader {
    width: 60px;
  }

  .big-loader-wheel {
    animation: spin 1s infinite linear;
    border: 5px solid rgba(30, 30, 30, 0.5);
    border-left: 4px solid #fff;
    border-radius: 50%;
    height: 60px;
    margin-bottom: 10px;
    width: 60px;
  }
  
  .loader-text {
    color: #404040;
    font-family: Poppins, sans-serif;
    margin-left: -2px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes load {
    0% {
      content: 'Loading';
    }
    33% {
      content: 'Loading.';
    }
    67% {
      content: 'Loading..';
    }
    100% {
      content: 'Loading...';
    }
  }